import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { getStoredXP, calculateMaxXP } from './functions/xp';
import { addSkinToLocalInventory } from './functions/inv';

function Clicker() {
    const [balance, setBalance] = useState(0);
    const [level, setLevel] = useState(() => parseInt(localStorage.getItem('level')) || 1);
    const [xp, setXP] = useState(getStoredXP());
    const [caseHistory, setCaseHistory] = useState([]);
    const [clicks, setClicks] = useState(0);
    const [streak, setStreak] = useState(0);
    const [floatingNumbers, setFloatingNumbers] = useState([]);
    const [canClick, setCanClick] = useState(true);
    const [storedCases, setStoredCases] = useState(0);

    var maxXP = calculateMaxXP(level);
    const clickTimeoutRef = useRef(null);
    const bonusCaseLock = useRef(false);
    const audioRefs = useRef([]);

    const soundPaths = [
        '/sounds/clicks/click_1.mp3',
        '/sounds/clicks/click_2.mp3',
        '/sounds/clicks/click_3.mp3',
        '/sounds/clicks/click_4.mp3',
        '/sounds/clicks/click_5.mp3'
    ];

    useEffect(() => {
        audioRefs.current = soundPaths.map((path) => {
            const audio = new Audio(path);
            audio.load(); // Preload audio
            return audio;
        });
    }, []);

    const fetchData = async (url, callback) => {
        try {
          const { data } = await axios.get(url, { withCredentials: true });
          callback(data);
        } catch (error) {
          console.error(`Error fetching from ${url}:`, error);
        }
    };

    const getStoredValue = (key, defaultValue) => localStorage.getItem(key) || defaultValue;

    useEffect(() => {
        setLevel(parseInt(getStoredValue('level', '1')));
        setXP(parseFloat(getStoredValue('xp', '0')));
        fetchData(`/api/xp`, ({ xp, level }) => {
            setXP(xp);
            setLevel(level);
            localStorage.setItem('xp', xp);
            localStorage.setItem('level', level);
        });
        fetchData(`/api/balance`, ({ balance }) => setBalance(balance));
    }, []);

    const updateStoredCasesCount = () => {
        const storedItems = JSON.parse(localStorage.getItem('skins')) || [];
        setStoredCases(storedItems.length);
    };

    useEffect(() => {
        updateStoredCasesCount();
    }, []);

    const updateDOMBalance = (newBalance) => {
        document.querySelectorAll('.balance').forEach(el => el.textContent = `$${newBalance}`);
    };

    const updateDOMElements = (newXP, newMaxXP, newLevel) => {
        document.querySelectorAll('.xp-info').forEach(el => el.textContent = `${newXP} / ${newMaxXP} XP`);
        document.querySelectorAll('.level').forEach(el => el.textContent = `Lv: ${newLevel}`);
        const xpPercent = Math.min(100, (newXP / newMaxXP) * 100);
        const xpBarElement = document.querySelector('#xp-bar');
        if (xpBarElement) xpBarElement.style.width = `${xpPercent}%`;
    };

    const updateCaseHistory = async (newToken) => {
        try {
            const response = await fetch('/api/decode-inventory', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ inventoryArray: [{ token: newToken }] })
            });

            if (!response.ok) throw new Error('Error decoding items.');

            const data = await response.json();
            setCaseHistory(prev => [...prev, ...data.items]);
        } catch (error) {
            console.error('Failed to decode inventory:', error);
        }
    };

    const handleBonusCaseDrop = async () => {
        if (bonusCaseLock.current || storedCases >= 200) return;

        bonusCaseLock.current = true;
        try {
            const response = await axios.post('/api/addBonusCase', {}, { withCredentials: true });
            if (response.status === 200) {
                const token = response.data.token;
                addSkinToLocalInventory({ token });
                await updateCaseHistory(token);
                updateStoredCasesCount();
            }
        } catch (error) {
            console.error('Error handling bonus case drop:', error);
        } finally {
            bonusCaseLock.current = false;
        }
    };

    const handleClick = () => {
        if (!canClick) return;

        const newXP = parseInt(xp) + 1;
        setXP(newXP);

        if (newXP >= maxXP) {
            setLevel(prevLevel => parseInt(prevLevel) + 1);
            maxXP = calculateMaxXP(level)
            setXP(0);
            axios.post('/api/level/increment', { time: Date.now() }, { withCredentials: true })
                .catch(error => console.error('Error updating balance:', error));
        }

        updateDOMElements(newXP, maxXP, level);
        setCanClick(false);
        setTimeout(() => setCanClick(true), 150);

        if (navigator.vibrate) navigator.vibrate(50);

        const randomIndex = Math.floor(Math.random() * audioRefs.current.length);
        audioRefs.current[randomIndex].play();

        clearTimeout(clickTimeoutRef.current);
        setStreak(prev => prev + 1);
        clickTimeoutRef.current = setTimeout(() => setStreak(0), 2000);

        setFloatingNumbers(prev => [...prev, { id: Date.now(), value: '+1' }]);
        setTimeout(() => setFloatingNumbers(prev => prev.filter(num => num.id !== Date.now())), 500);

        setClicks(prev => prev + 1);

        const newBalance = parseFloat(balance) + 0.01;
        setBalance(newBalance.toFixed(2));
        updateDOMBalance(newBalance.toFixed(2));

        if ((clicks + 1) % 100 === 0) handleBonusCaseDrop();

        axios.post('/api/balance/click', { time: Date.now() }, { withCredentials: true })
            .catch(error => console.error('Error updating balance:', error));
        
        axios.post('/api/xp/click', { time: Date.now() }, { withCredentials: true })
            .catch(error => console.error('Error updating xp and level:', error));
    };

    useEffect(() => {
        document.title = `Clicks: ${clicks} | Level: ${level}`;
    }, [clicks, level]);

    return (
        <div className="clicker-container">
            <div className="clicker">
                <div className="clickable-area" onClick={handleClick}>
                    Click Me!
                    {floatingNumbers.map(num => (
                        <span key={num.id} className="floating-number">{num.value}</span>
                    ))}
                </div>
                <div className="clicker-stats">
                    <div
                        id="bonus-bar"
                        className="bonus-bar"
                        style={{ width: `${((clicks % 100) / 100) * 100}%` }}
                    ></div>
                    <p>Clicks to next bonus: {100 - (clicks % 100)}</p>
                    <p>Current Streak: {streak}</p>
                </div>
            </div>
            <div className="case-history">
                <h3>Case History</h3>
                <p className={`case-history-p ${storedCases >= 200 ? 'red' : storedCases >= 150 ? 'orange' : 'white'}`}>
                    Stored Items: {storedCases}
                </p>
                <ul>
                    {caseHistory.map((caseItem, index) => (
                        <li key={index}>
                            {caseItem.name} - ${caseItem.price}
                            <img src={caseItem.image} alt={caseItem.name} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Clicker;
