import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from './language'; 
import { increaseBalance, getStoredBalance } from './functions/balance'; 

function Inventory() {
  const [balance, setBalance] = useState(getStoredBalance());
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { translations } = useLanguage();
  const [isSelling, setIsSelling] = useState(false); // Button is enabled initially
  const navigate = useNavigate();

  // Filter and sorting states
  const [priceFilter, setPriceFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');

  // Define rarity colors
  const rarityColors = {
    'Covert': '#DC4444',
    'Classified': '#CD2ADB',
    'Restricted': '#733CDB',
    'Mil-spec': '#4362E6',
    'Industrial': '#5899D3',
    'Consumer': '#BAC9DA',
    'Special': '#E5A725'
  };

  const fetchAndDecodeInventory = async () => {
    try {
      const localSkins = JSON.parse(localStorage.getItem('skins')) || [];

      const inventoryArray = [ ...localSkins];

      if (inventoryArray.length === 0) {
        setItems([]);
        setLoading(false);
        return;
      }
      console.log(inventoryArray);

      const response = await fetch(`/api/decode-inventory`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ inventoryArray }),
      });

      const data = await response.json();
      console.log(data);

      if (response.ok) {
        setItems(data.items);
      } else {
        setError(data.error || 'Error decoding items.');
      }

    } catch (err) {
      setError('Failed to fetch inventory.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAndDecodeInventory();
  }, []); 

  useEffect(() => {
    const loadBalance = () => {
      setBalance(getStoredBalance());
      document.querySelectorAll('#balance').forEach((element) => {
        element.textContent = `${balance}`;
      });
    };

    loadBalance();
  }, [balance]);

  const filterItems = (item) => {
    const maxPrice = parseFloat(priceFilter);
    return isNaN(maxPrice) || item.price <= maxPrice;
  };

  const sortItems = (unsortedItems) => {
    return [...unsortedItems].sort((a, b) => {
      return sortOrder === 'asc' ? a.price - b.price : b.price - a.price;
    });
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  // Function to handle case button click
  const handleCaseButtonClick = async (caseItem) => {
    try {
      // Fetch all cases from the backend
      const response = await fetch(`/api/cases`);
      
      if (!response.ok) {
        console.error("Failed to fetch cases from backend.");
        return; // Exit if cases could not be retrieved
      }

      const cases = await response.json();

      // Find the case with the matching name
      const matchedCase = cases.find((c) => c.name === caseItem.name);

      if (matchedCase) {
        // Navigate to CaseOpenerPage with the selected case containing the correct ID
        navigate('/caseopener', { state: { selectedCase: { ...caseItem, id: matchedCase.id } } });
        console.log("Navigating with case:", matchedCase);
      } else {
        console.warn("Case with name", caseItem.name, "not found in backend.");
      }
    } catch (error) {
      console.error("Error finding case by name:", error);
    }
  };



  const sellInventoryFully = () => {
    try {
        // Get the inventory from local storage
        const localSkins = JSON.parse(localStorage.getItem('skins')) || [];
        
        if (localSkins.length === 0) {
            console.log('No skins found in local storage to erase.');
            return;
        }

        // Clear skins from local storage
        localStorage.removeItem('skins');

        // Clear UI items (if applicable)
        setItems([]); // Assuming this updates the displayed inventory on the UI

        console.log('All skins successfully erased from local storage.');
    } catch (error) {
        console.error('Error clearing skins from local storage:', error);
    }
  };



  const sellItem = async (item) => {
    try {
        setIsSelling(true); // Disable buttons
        setItems((prevItems) => prevItems.filter((i) => i.id !== item.id));
        const localSkins = JSON.parse(localStorage.getItem('skins')) || [];
        const inventoryArray = [ ...localSkins];

        console.log('Sending payload:', { inventoryArray, id: item.id });

        const response = await fetch(`/api/incItem`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ inventoryArray, id: item.id }), // Pass id directly
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Backend error:', errorData);
            throw new Error(errorData.error || 'Failed to sell item');
        }

        const data = await response.json();
        console.log('Items returned:', data);

        // Update local storage with the new inventory
        localStorage.setItem('skins', JSON.stringify(data.tokens)); 

        // Update the balance in the frontend
        const newBalanceResponse = await fetch(`/api/balance`);
        const balanceData = await newBalanceResponse.json();
        setBalance(balanceData.balance);
    } catch (error) {
        console.error('Error selling item:', error);
    } finally {
      setIsSelling(false); // Re-enable buttons
    }
  };

  return (
    <div className="inventory-section">
      <h2>{translations.inventoryTitle}</h2>

      {/* Filter Section */}
      <div className="filter-section">
        <label>
          {translations.priceFilter}
          <input
            type="number"
            value={priceFilter}
            onChange={(e) => setPriceFilter(e.target.value)}
            placeholder={translations.priceFilter}
          />
        </label>

        <button onClick={toggleSortOrder} className="sort-button">
          {translations.sortByPrice} ({sortOrder === 'asc' ? translations.ascending : translations.descending})
        </button>

        <button onClick={sellInventoryFully} className="clear-button" disabled={isSelling}>
          {translations.clearInventory}
        </button>
      </div>

      {/* Inventory Items Section */}
      <div className="inventory-container">
        {loading && <div className="loading-message">{translations.loadingItems}</div>} {/* Show loading message */}
        {error && <div className="error-message">{translations.errorLoading}</div>} {/* Show error message */}
        
        {!loading && !error && sortItems(items.filter(filterItems)).map(item => (
          <div
            className="inventory-item"
            key={item.id}
            style={{
              background: `linear-gradient(135deg, ${rarityColors[item.rarity] || '#4362E6'} 1%, rgba(0, 0, 0, 0.7) 120%)`,
              color: '#fff' 
            }}
          >
            <img src={item.image} onClick={() => handleCaseButtonClick(item)} alt={item.name} />
            <h3>{item.name}</h3>
            {item.quality && <p className="quality">{translations.quality}: {item.quality}</p>}
            <p className="price">{translations.price}: ${item.price}</p>

            <button
            onClick={(e) => {
              e.stopPropagation();
              sellItem(item);
            }}
            className="sell-button" disabled={isSelling} // Disable when `isSelling` is true
            >
              Sell
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Inventory;
