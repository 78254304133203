import React, { useState, useEffect } from 'react';
import { showSuccessfulLogin, showSuccessfulSignup } from './functions/errors';
import './Modal.css';

const Profile = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSignup, setIsSignup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(localStorage.getItem('profileImage') || '');
  const [images, setImages] = useState([]);
  const [clickCount, setClickCount] = useState(0);


  // Fetch all the webp images from the public/icons folder
  useEffect(() => {
    const importAll = (r) => r.keys().map(r);
    const icons = importAll(require.context('../public/icons', false, /\.webp$/));
    setImages(icons);
  }, []);

  // Close the modal
  const closeModal = () => {
    document.getElementById('profile-modal').style.display = 'none';
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Handle signup
  const handleSignup = async (event) => {
    event.preventDefault();
    if (!username || !password) {
      setErrorMessage('Please enter both a username and password.');
      return;
    }
    try {
      const response = await fetch(`/api/auth/signup`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
        credentials: 'include',
      });
      const data = await response.json();
      if (response.ok) {
        showSuccessfulSignup();
        setSuccessMessage(data.message);
        setErrorMessage('');
        localStorage.setItem('userId', data.userId);
        localStorage.setItem('username', username);
        closeModal();
      } else {
        setErrorMessage(data.error);
        setSuccessMessage('');
      }
    } catch (error) {
      setErrorMessage('An error occurred during signup.');
      setSuccessMessage('');
    }
  };

  // Handle login
  const handleLogin = async (event) => {
    event.preventDefault();
    if (!username || !password) {
      setErrorMessage('Please enter both a username and password.');
      return;
    }
    try {
      const response = await fetch(`/api/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
        credentials: 'include',
      });
      const data = await response.json();
      if (response.ok) {
        showSuccessfulLogin();
        setSuccessMessage(data.message);
        setErrorMessage('');
        localStorage.setItem('userId', data.userId);
        localStorage.setItem('username', username);
        closeModal();
      } else {
        setErrorMessage(data.error);
        setSuccessMessage('');
      }
    } catch (error) {
      setErrorMessage('An error occurred during login.');
      setSuccessMessage('');
    }
  };

  const handleIconClick = (image) => {
    if (image.includes('a3temii')) { // adjust path match if necessary
      setClickCount((prev) => prev + 1);
      if (clickCount === 2) { // play on third click
        console.log("Should work")
        const audio = new Audio('/sounds/Silhouette.mp3');
        audio.play();
        setClickCount(0); // Reset after playing
      }
    } else {
      setClickCount(0); // Reset count if other image is clicked
    }
  };

  return (
    <div>
      <div id="profile-modal" className="modal">
        <div className="modal-content">
          <span className="close" onClick={closeModal}>&times;</span>
          <h2>{isSignup ? 'Signup' : 'Login'}</h2>
          <form onSubmit={isSignup ? handleSignup : handleLogin}>
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              placeholder="Enter username"
              onChange={(e) => setUsername(e.target.value)}
            />
            <label htmlFor="password">Password:</label>
            <div style={{ position: 'relative' }}>
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                value={password}
                placeholder="Enter password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '43%',
                  transform: 'translateY(-43%)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer'
                }}
              >
                <img
              src={showPassword ? '/eye-solid.png' : '/eye-slash-solid.png'}
              alt="toggle password visibility"
              onClick={togglePasswordVisibility}
              style={{
                right: '10px',
                cursor: 'pointer',
                height: '20px',
                alignItems: 'center',
              }}
            />
              </button>
            </div>
            <p className="error-message">{errorMessage}</p>
            <p className="success-message">{successMessage}</p>
            <button type="submit">{isSignup ? 'Signup' : 'Login'}</button>
          </form>

          <h3 className='modal-content-frick'>Choose a profile picture:</h3>
          <div className="image-gallery">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`icon-${index}`}
                onClick={() => {
                  setSelectedImage(image);
                  handleIconClick(image);
                }}
                className={selectedImage === image ? 'selected-image' : ''}
              />
            ))}
          </div>
          <p>
            {isSignup ? 'Already have an account?' : 'Don’t have an account?'}{' '}
            <button onClick={() => setIsSignup(!isSignup)} className="toggle-auth-button">
              {isSignup ? 'Login' : 'Signup'}
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
