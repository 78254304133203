import React, { useState, useEffect, useCallback } from 'react';
import { addSkinToLocalInventory } from './functions/inv';
import axios from 'axios'; 
import './Casino.css';

const Casino = () => {
  const [imgSrc, setImgSrc] = useState('../slot_machine.png');
  const [prizes, setPrizes] = useState([]);
  const [showTicketPopup, setShowTicketPopup] = useState(false);
  const [jackpotItem, setJackpotItem] = useState(null);
  const [ticketCounts, setTicketCounts] = useState({
    bronz_tick: 0,
    silv_tick: 0,
    gold_tick: 0,
  });

  const ticketOptions = [
    { name: 'Bronze Ticket', image: 'bronze_ticket.png', type: 'bronz_tick' },
    { name: 'Silver Ticket', image: 'silver_ticket.png', type: 'silv_tick' },
    { name: 'Gold Ticket', image: 'gold_ticket.png', type: 'gold_tick' },
  ];

  const prizeMap = {
    Special: 'gold_prize_icon.png',
    Covert: 'red_prize_icon.png',
    Classified: 'pink_prize_icon.png',
    Restricted: 'purple_prize_icon.png',
    'Mil-spec': 'blue_prize_icon.png',
    Industrial: 'cyan_prize_icon.png',
    Consumer: 'gray_prize_icon.png',
  };

  // Preload images for smoother UI
  useEffect(() => {
    const preloadImages = () => {
      const imagePaths = [
        '../slot_machine.png',
        '../slot_machine_anim_once.gif',
        ...Object.values(prizeMap),
        ...ticketOptions.map((ticket) => `/${ticket.image}`),
      ];
      imagePaths.forEach((path) => {
        const img = new Image();
        img.src = path;
      });
    };
    preloadImages();
  }, []);

  // Fetch ticket counts from API
  const fetchTicketCounts = useCallback(async () => {
    try {
      const response = await fetch('/api/tickets', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch ticket counts');
      }

      const data = await response.json();
      setTicketCounts(data);
    } catch (error) {
      console.error('Error fetching ticket counts:', error.message);
    }
  }, []);

  useEffect(() => {
    fetchTicketCounts();
  }, [fetchTicketCounts]);

  const handleSlotPlay = async (ticketType) => {
    try {
      setTicketCounts((prevCounts) => ({
        ...prevCounts,
        [ticketType]: prevCounts[ticketType] - 1,
      }));
  
      setImgSrc(`../slot_machine_anim_once.gif?${Date.now()}`);
  
      const response = await fetch('/api/slotPlay', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ chosenTicket: ticketType }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to play slot');
      }
  
      const data = await response.json();
      const randomPrizes = data.randomTickets.map((prize) => ({
        src: prizeMap[prize],
      }));
  
      setPrizes([]);
      const positions = [
        { top: '37%', left: '21.5%' },
        { top: '37%', left: '38.5%' },
        { top: '37%', left: '55%' },
      ];
  
      setTimeout(() => {
        positions.forEach((position, index) => {
          setTimeout(() => {
            setPrizes((prevPrizes) => [
              ...prevPrizes,
              { src: randomPrizes[index].src, position },
            ]);
          }, index * 1);
        });
      }, 1);
  
      setTimeout(() => setImgSrc('../slot_machine.png'), 1);

      if (data.tokenizedItem) {
        addSkinToLocalInventory(data.tokenizedItem);
        setTimeout(async () => {
        try {
          const response = await axios.post(`/api/decode-inventory`, {
            inventoryArray: [{ token: data.tokenizedItem.token }],
          });
    
          if (response.status === 200) {
            setJackpotItem(response.data.items[0]);
          } else {
            console.error('Error decoding item:', response.data.error);
          }
        } catch (error) {
          console.error('Error calling decode API:', error);
        }
      }, 1 )
      };
    } catch (error) {
      console.error('Error during slot play:', error.message);
  
      setTicketCounts((prevCounts) => ({
        ...prevCounts,
        [ticketType]: prevCounts[ticketType] + 1,
      }));
    }
  };
  
  

  // Handle ticket selection
  const handleTicketSelect = (ticket) => {
    setShowTicketPopup(false);
    for (let i = 0; i < 2; i++){
      handleSlotPlay(ticket.type);
    };
  };

  return (
    <div className="casino-section-unique" style={{ backgroundImage: 'url(/CasinoBack.webp)' }}>
      <div className="slot-machine-container">
        {prizes.map((prize, index) => (
          <img
            key={index}
            src={`/${prize.src}`}
            alt={`Prize ${index + 1}`}
            className="prize-icon"
            style={{
              position: 'absolute',
              top: prize.position.top,
              left: prize.position.left,
            }}
          />
        ))}
        <img className="slot-machine" src={imgSrc} alt="Slot Machine" />
      </div>

      <button className="button-unique" onClick={() => setShowTicketPopup(!showTicketPopup)}>
        Play Slot Machine
      </button>

      {showTicketPopup && (
        <div className="ticket-popup">
          <h3>Select a Ticket</h3>
          <div className="ticket-options">
            {ticketOptions.map((ticket) => (
              <div
                key={ticket.name}
                className="ticket-option"
                onClick={() => handleTicketSelect(ticket)}
              >
                <img src={`/${ticket.image}`} alt={ticket.name} />
                <p>{ticket.name}</p>
                <p>
                  <strong>{ticketCounts[ticket.type]}</strong> available
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
      
      {jackpotItem && (
        <div className="jackpot-item">
          <h2>🎉 Jackpot Won! 🎉</h2>
          <img src={jackpotItem.image} alt={jackpotItem.name} />
          <p>Name: {jackpotItem.name}</p>
          <p>Quality: {jackpotItem.quality}</p>
          <p>Price: {jackpotItem.price}</p>
        </div>
      )}
    </div>
  );
};

export default Casino;
