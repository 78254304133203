// frontend/src/functions/errors.js

let setError;

export const registerErrorFieldSetter = (setErrorCallback) => {
  setError = setErrorCallback;
};

// Function to display an insufficient balance error
export const showInsufficientBalanceError = () => {
  if (setError) {
    setError("Insufficient balance to purchase this item.");
  } else {
    console.error("Error field setter is not registered.");
  }
};

export const showSuccessfulLogin = () => {
  if (setError) {
    setError("Login successful!");
  } else {
    console.error("Error field setter is not registered.");
  }
};

export const showSuccessfulSignup = () => {
  if (setError) {
    setError("Signup successful!");
  } else {
    console.error("Error field setter is not registered.");
  }
};