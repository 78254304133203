import React, { useState, useEffect, useRef } from 'react';
import gsap from 'gsap';
import axios from 'axios';
import './index.css';

const LuckyWheel = () => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [segmentData, setSegmentData] = useState([]);
  const [message, setMessage] = useState("Please choose a side: Left or Right."); // Initial message
  const [userChoice, setUserChoice] = useState(null); // Track user choice (left or right)
  const [canSpin, setCanSpin] = useState(false); // Track if the user can spin
  const wheelRef = useRef(null);

  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    const checkLastSpinTime = async () => {
      try {
        const currentTimestamp = new Date().toISOString();
        const response = await axios.post('/api/dailyWheel', { currentTimestamp });
        const { canSpin, lastDailyWheel } = response.data;

        setCanSpin(canSpin);
        if (canSpin) {
          setMessage("Choose a side and spin!");
        } else {
          const lastSpinDate = new Date(lastDailyWheel);
          const hoursLeft = 20 - (new Date() - lastSpinDate) / (1000 * 60 * 60);
          const countdownDate = new Date(new Date().getTime() + hoursLeft * 3600 * 1000);
          setTimeRemaining(countdownDate - new Date());
        }
      } catch (error) {
        console.error('Error checking last spin time:', error);
        setMessage('Consider logging in to claim your daily spin award!');
      }
    };

    checkLastSpinTime();
  }, []);

  useEffect(() => {
    if (timeRemaining === null) return;
  
    const intervalId = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(intervalId);
          setCanSpin(true);
          setMessage("Choose a side and spin!");
          return null;
        }
        return prevTime - 1000;
      });
    }, 1000);
  
    return () => clearInterval(intervalId);
  }, [timeRemaining]);

  useEffect(() => {
    if (!canSpin && timeRemaining !== null) {
      const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
      const seconds = Math.floor((timeRemaining / 1000) % 60);
      setMessage(`You can spin again in ${hours}h ${minutes}m ${seconds}s`);
    }
  }, [timeRemaining, canSpin]);  

  // Fetch segments when the component mounts
  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const response = await fetch('/api/generateSegments');
        const data = await response.json();
        setSegmentData(data.segments);
      } catch (error) {
        console.error('Error fetching segment data:', error);
        setMessage('Failed to load wheel segments. Please try again later.');
      }
    };

    fetchSegments();
  }, []);

  const segmentAngle = 360 / segmentData.length; // Angle per segment
  const segmentWidth = 100 / segmentData.length; // Dynamic width based on the number of segments

  const closeModal = () => {
    document.getElementById('lucky-wheel-cont').style.display = 'none';
  };

  const updateDOMBalance = (newBalance) => {
    document.querySelectorAll('.balance').forEach(el => el.textContent = `$${newBalance}`);
};

  const spinWheel = () => {
    if (isSpinning || !userChoice || !canSpin) return; // Prevent spinning if already spinning or no choice made
    setIsSpinning(true);
    setMessage("Spinning the wheel... Good luck!");

    const randomTurns = Math.floor(Math.random() * 4) + 4; // Random number of full turns (4-8)
    const randomSegment = Math.floor(Math.random() * segmentData.length); // Random segment offset
    const randomDegree = randomTurns * 360 + randomSegment * segmentAngle; // Target rotation

    gsap.to(wheelRef.current, {
      rotation: randomDegree,
      duration: 15,
      ease: 'power4.out',
      onComplete: () => {
        setIsSpinning(false);
        determineSegmentPositions(randomDegree);
      },
    });
  };

  const determineSegmentPositions = async (finalRotation) => {
    const normalizedRotation = finalRotation % 360;
    const rightSegmentIndex = Math.floor(
      (360 - normalizedRotation + segmentAngle / 2) % 360 / segmentAngle
    ) % segmentData.length;
    const leftSegmentIndex =
      (rightSegmentIndex + Math.floor(segmentData.length / 2)) % segmentData.length;
  
    const chosenSegment =
      userChoice === "left"
        ? segmentData[leftSegmentIndex]
        : segmentData[rightSegmentIndex];
  
    try {
      const response = await axios.post('/api/calculateReward', {
        finalRotation,
        userChoice,
        segmentData
      });
  
      const { reward, balance } = response.data;
  
      setMessage(
        <span>
          Congratulations! You win <img src="dollar_icon.png" alt="Dollar Icon" style={{ width: "30px", verticalAlign: "middle" }} /> {reward}!<br />
          Your new balance is: <strong>{balance}</strong>
        </span>
      );

      updateDOMBalance(balance);
      // Refresh eligibility status after spinning
      setCanSpin(false); // Disable spinning until 20 hours have passed
    } catch (error) {
      console.error('Error updating reward:', error);
      setMessage('An error occurred while updating your reward. Please try again.');
    }
  };  

  const handleChoice = (side) => {
    if (isSpinning) return;
    setUserChoice(side);
    setMessage(`You chose the ${side.toUpperCase()} side. Now, spin the wheel!`);
  };

  return (
    <div id="lucky-wheel-cont" className="lucky-wheel-cont">
      <div className="modal-content-wheel">
        <h2>Spin the Lucky Wheel!</h2>
        <button className="close-btn" onClick={closeModal}>
          Close
        </button>
        <div className="wheel-choice">
          <button
            className="left-button"
            onClick={() => handleChoice("left")}
            disabled={isSpinning || !canSpin}
          >
            <img src="left_twix.png" alt="Left Twix" />
          </button>
          <div className="wheel-container">
            <div ref={wheelRef} className="wheel">
              {segmentData.map((segment, index) => (
                <div
                  key={segment.id}
                  className={`segment segment-${index + 1}`}
                  style={{
                    transform: `rotate(${index * segmentAngle}deg)`,
                    clipPath: `polygon(50% 50%, 100% ${(60 + segmentWidth / 2)}%, 100% ${(40 - segmentWidth / 2)}%)`,
                  }}
                >
                  <div className="prize">
                    <img
                      src="dollar_icon.png"
                      className="dollar-icon"
                      alt="Prize"
                    />
                    <h3>{segment.text}</h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button
            className="right-button"
            onClick={() => handleChoice("right")}
            disabled={isSpinning || !canSpin}
          >
            <img src="right_twix.png" alt="Right Twix" />
          </button>
          <button
            className="spin-button"
            onClick={spinWheel}
            disabled={isSpinning || !userChoice || !canSpin}
          >
            <img src="spin_icon.png" alt="Spin" />
          </button>
        </div>
        <p className="wheel-message">
          {message}
        </p>
      </div>
    </div>
  );
};

export default LuckyWheel;
